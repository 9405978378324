import React, { useState } from "react";
import { Tag } from 'antd';
import { LoadingOutlined } from "@ant-design/icons";
import { varBgColor, varColor, varLabel, varList } from "../../common/var";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { asPercent } from "../../utils/text";

function MLSafetyResources({
    resources,
    loadResources,
    hasMore,
    isLoading,
    isShowFeedback,
    onProvideFeedback,
    isUploadingFb,
    updatingPaper,
    updatingCategory
}) {
    const [disabledAbstracts, setDisabledAbstracts] = useState([]);

    const onDisableAbstract = (index) => {
        let disabledAbstracts0 = disabledAbstracts.filter((el) => el != index);
        setDisabledAbstracts(disabledAbstracts0);
        document.getElementById(`abstract${index}`).style.webkitLineClamp = 3;
    }

    const onEnableAbstract = (index) => {
        const disabledAbstracts0 = [
            ...disabledAbstracts,
            index
        ];
        setDisabledAbstracts(disabledAbstracts0);
        document.getElementById(`abstract${index}`).style.webkitLineClamp = "unset";
    }

    return (
        <div className="ml-safety-resources">
            <div className="go-to-top" id="topIcon"><img src="images/top.png" /></div>
            <InfiniteScroll
                dataLength={resources.length}
                next={loadResources}
                hasMore={hasMore}
                loader={<div className="loading-resources-with-data"><LoadingOutlined /></div>}
            >
                {resources.map((el, index) => (
                    <div className="resource" key={index}>
                        <div className="title">
                            <div className="title-link">
                                <h3><a href={el.link} target={"_blank"}><span className="black">{el.title}</span><img src="images/link.svg" /></a>
                                    <Tag
                                        style={{ 
                                            color: varColor("category", el.category),
                                            backgroundColor: varBgColor("category", el.category)
                                        }}
                                        className="category-tag"
                                    >
                                        {varLabel("category", el.category)}{isShowFeedback && ` (${asPercent(el.category_score)})`}
                                    </Tag>
                                </h3>
                            </div>
                        </div>
                        <div className="author-info">
                            <span>
                                {el.authors.map((author, idx) => idx < 3 &&
                                    <i key={idx}>{(idx == (el.authors.length - 1) || idx == 2) ? (author) : (`${author}, `)}</i>
                                )}
                            </span>
                            <span> · {moment(el.published).fromNow()}</span>
                        </div>
                        <div className="abstract-section">
                            <span
                                id={`abstract${index}`}
                                className="abstract"
                                onClick={() => disabledAbstracts.indexOf(index) >= 0 ? onDisableAbstract(index) : onEnableAbstract(index)}
                            >
                                {el.abstract}
                            </span>
                            {disabledAbstracts.indexOf(index) >= 0 ?
                                <a className="hide-detail" onClick={() => onDisableAbstract(index)}>&#8963;</a>
                                :
                                <a className="hide-detail" onClick={() => onEnableAbstract(index)}>&#8964;</a>
                            }
                        </div>
                        {isShowFeedback &&
                            <div className="feedback">
                                <span className="feedback-title">Correct classification:</span>
                                <div className="feedback-categories">
                                    {varList("category").map((ct, indx) => (
                                        <Tag
                                            style={{ 
                                                color: ct.text_color,
                                                backgroundColor: ct.bg_color
                                            }}
                                            className="feedback-tag"
                                            key={indx}
                                            onClick={() => onProvideFeedback(ct.key, el._id)}
                                        >
                                            {(isUploadingFb && updatingCategory == ct.key && updatingPaper == el._id) ? <LoadingOutlined /> : ct.label}
                                        </Tag>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                ))}
            </InfiniteScroll>
            {resources.length == 0 && isLoading && (
                <div className="loading-resources"><LoadingOutlined /></div>
            )}
            {resources.length == 0 && !isLoading && (
                <div className="no-data">
                    <span>No papers match your filters</span>
                </div>
            )}
        </div>
    );
}

export default MLSafetyResources;