import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import Layout from '../components/common/Layout';
import FilterNavigation from '../components/homepage/FilterNavigation';
import MLSafetyResources from '../components/homepage/MLSafetyResources';
import { callGetApi, callPostApi } from "../utils/api";

function Home() {
    const [formData, setFormData] = useState({
        selectiveness: 0.90,
        robustness: true,
        monitoring: true,
        alignment: true,
        systemic: true,
        limit_to: 10,
    });
    const [start, setStart] = useState(0);
    const [resources, setResources] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowFeedback, setIsShowFeedback] = useState(false);
    const [isUploadingFb, setIsUploadingFb] = useState(false);
    const [updatingPaper, setUpdatingPaper] = useState(undefined);
    const [updatingCategory, setUpdatingCategory] = useState(undefined);

    const onChangeFormData = (field, value) => {
        setStart(0);
        setFormData({
            ...formData,
            [field]: value
        });
        setResources([]);
    }

    const onChangeFeedback = (checked) => {
        setIsShowFeedback(checked);
    }

    const onRefresh = () => {
        setStart(0);
        setFormData({
            selectiveness: 0.90,
            robustness: true,
            monitoring: true,
            alignment: true,
            systemic: true,
            limit_to:10
        });
        setResources([]);
    }

    const onSuccessUpdatedFeedback = (correctCategory, paperId) => {
        setIsUploadingFb(false);
        setUpdatingCategory(undefined);
        setUpdatingPaper(undefined);
        message.success("Your feedback has been recorded. Thanks for improving the site!");
        if (formData[correctCategory] == false || correctCategory == "non_safety") {
            setResources([
                ...resources.filter((el) => el._id != paperId).map((el) => (el))
            ])
        }
    }

    const onFailedUpdatedFeedback = (err) => {
        setIsUploadingFb(false);
        setUpdatingCategory(undefined);
        setUpdatingPaper(undefined);
        message.error(err);
    }

    const onProvideFeedback = (correctCategory, paperId) => {
        setIsUploadingFb(true);
        setUpdatingCategory(correctCategory);
        setUpdatingPaper(paperId);
        const data = {
            id: paperId,
            relabel: correctCategory
        }
        callPostApi("update", data, () => onSuccessUpdatedFeedback(correctCategory, paperId), onFailedUpdatedFeedback);
    }

    const onSuccessGetResources = (res) => {
        setIsLoading(false);
        setStart(res.lastIndex);
        setHasMore(res.hasMore);
        setResources([
            ...resources.map((el) => (el)),
            ...res.papers.map((el) => (el))
        ]);
    }

    const onFailedGetResources = (err) => {
        setIsLoading(false);
        setResources([]);
        message.error(err);
    }

    const loadResources = () => {
        const query = `sensitivity=${formData.selectiveness}&robustness=${formData.robustness}&monitoring=${formData.monitoring}&alignment=${formData.alignment}&systemic=${formData.systemic}&limit_to=${formData.limit_to}&start=${start}`;
        setIsLoading(true);
        callGetApi(`page?${query}`, onSuccessGetResources, onFailedGetResources);
    }

    useEffect(() => {
        loadResources();
    }, [formData]);

    return (
        <Layout>
            <div className='home-page'>
                <FilterNavigation
                    formData={formData}
                    onChangeFormData={onChangeFormData}
                    onChangeFeedback={onChangeFeedback}
                    onRefresh={onRefresh}
                    isShowFeedback={isShowFeedback}
                />
                <MLSafetyResources
                    resources={resources}
                    loadResources={loadResources}
                    hasMore={hasMore}
                    isLoading={isLoading}
                    isShowFeedback={isShowFeedback}
                    onProvideFeedback={onProvideFeedback}
                    isUploadingFb={isUploadingFb}
                    updatingPaper={updatingPaper}
                    updatingCategory={updatingCategory}
                />
            </div>
        </Layout>
    );
}

export default Home;