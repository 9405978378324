import React from "react";

function Layout({ children }) {
    return (
        <>
            <header>
                <button className="nav-icon" id="menubutton"><img src="images/menu.svg" /></button>
                <a href="/"><img className="logo" src="images/logo.svg" /></a>
                <a className="more-resources-desktop" href="https://mlsafety.org" target={"_blank"}>More ML Safety Resources</a>
            </header>
            {children}
        </>
    );
}

export default Layout;