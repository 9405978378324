import React from "react";
import { Input, Checkbox, Switch } from "antd";
import { varList, varValue } from "../../common/var";

function FilterNavigation({ 
    formData,
    onChangeFormData,
    onChangeFeedback,
    isShowFeedback,
    onRefresh
}) {
    const categories = varList("category");

    return (
        <div className="filter-navigation left-menu">
            <div className="more-resources mobile-view"><a href="https://www.mlsafety.org/" target={"_blank"}>More ML Safety Resources</a></div>
            <div className="selectiveness">
                <h5 className="right-space">Selectiveness</h5>
                <Input
                    type='number'
                    value={formData.selectiveness} 
                    onChange={(e )=> onChangeFormData('selectiveness', e.target.value)}
                    step={0.05}
                    max={1}
                    min={0}
                />
            </div>
            <div className="topics">
                <h5>Topics</h5>
                {categories.map((el, index) => el.value != varValue("category", "non_safety") && (
                    <div className="category" key={index}>
                        <Checkbox
                            onChange={() => onChangeFormData(el.key, !formData[el.key])}
                            checked={formData[el.key]}
                        >
                            {el.label}
                        </Checkbox>
                    </div>
                ))}
            </div>
            <div className="feedback-switch">
                <span>Provide feedback on classifications</span>
                <Switch
                    checked={isShowFeedback}
                    size="small"
                    onChange={(checked) => onChangeFeedback(checked)}
                />
            </div>
            {/* <div className="action">
                <button onClick={onRefresh}>Refresh</button>
            </div> */}
        </div>
    );
}

export default FilterNavigation;