import React from 'react';
import {createBrowserHistory} from 'history';
import { Routes, Route } from "react-router-dom";

import Home from './pages/Home';

const appHistory = {
  basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);

function App() {
  return (
    <Routes basename={process.env.PUBLIC_URL} history={history}>
      <Route index element={<Home />} />
        {/* <Route path="*" element={<NoPage />} /> */}
    </Routes>
  );
}

export default App;
